// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autorska-prava-index-jsx": () => import("./../../../src/pages/autorska-prava/index.jsx" /* webpackChunkName: "component---src-pages-autorska-prava-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-galerie-index-js": () => import("./../../../src/pages/galerie/index.js" /* webpackChunkName: "component---src-pages-galerie-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-novinky-index-jsx": () => import("./../../../src/pages/novinky/index.jsx" /* webpackChunkName: "component---src-pages-novinky-index-jsx" */),
  "component---src-pages-reference-index-jsx": () => import("./../../../src/pages/reference/index.jsx" /* webpackChunkName: "component---src-pages-reference-index-jsx" */),
  "component---src-pages-sluzby-bryle-index-jsx": () => import("./../../../src/pages/sluzby/bryle/index.jsx" /* webpackChunkName: "component---src-pages-sluzby-bryle-index-jsx" */),
  "component---src-pages-sluzby-cocky-index-jsx": () => import("./../../../src/pages/sluzby/cocky/index.jsx" /* webpackChunkName: "component---src-pages-sluzby-cocky-index-jsx" */),
  "component---src-pages-sluzby-doplnky-index-jsx": () => import("./../../../src/pages/sluzby/doplnky/index.jsx" /* webpackChunkName: "component---src-pages-sluzby-doplnky-index-jsx" */),
  "component---src-pages-sluzby-index-js": () => import("./../../../src/pages/sluzby/index.js" /* webpackChunkName: "component---src-pages-sluzby-index-js" */),
  "component---src-pages-sluzby-optometrie-index-jsx": () => import("./../../../src/pages/sluzby/optometrie/index.jsx" /* webpackChunkName: "component---src-pages-sluzby-optometrie-index-jsx" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

